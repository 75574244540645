import React, { Component, PureComponent } from 'react';
import classNames from 'classnames';
import { Link } from "react-router-dom";

import "../style/cible.scss";
import animations from "../animations";
import { GET_CURRENT_NUMERO, UPDATE_CURRENT_NUMERO } from "../../../../../Queries";
// import 'react-html5video/dist/styles.css';

import blank from "../blank.svg";
import { animationsState } from "../animationsState";

let supportsTouch, clientWidth, clientHeight;

if(typeof window !== "undefined"){
    supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
    const body = document.body;

    if(supportsTouch) body.classList.add("touch");

    clientWidth = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    clientHeight = window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;
}

class ProgressiveContainerImage extends Component {

    constructor(props){
        super(props);

        this.state = {
            o: 0,
        }

    }

    shouldComponentUpdate(nextProps, nextState) {
        return !(nextProps.src === this.props.src || this.props.src === null)
    }

    handle() {
        //this.props.handleImageLoaded();
    }

    loadImageSrc = () => { let self = this;

        let img = new Image();

        img.onload = function () {
            self.handle();
        };

        img.src = this.props.src;
    };


    render() {
        const { setRef, classe, src, number } = this.props;

        return(
            <ImageWithStatusText
                imageUrl={ src }
                handleImageLoaded={ this.loadImageSrc }
                loaded={ this.props.loaded }
                classe={ classe }
                setRef={ setRef }
                number={ number }
            />
        );

    }
}

class ProgressiveContainer extends Component {

    constructor(props){
        super(props);

        this.animation = null;

        this.state = {
            oldImage: null,
            newImage: null,
            loaded: false,
            firstLoad: false,
            current: 0
        };

        this.setRef = this.setRef.bind(this);

    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.src !== prevState.newImage){

            if(!prevState.oldImage){
                return {
                    oldImage: nextProps.src,
                    newImage: nextProps.src,
                    current: prevState.current
                }
            } else {
                if(prevState.newImage && nextProps.src){
                    return {
                        oldImage: prevState.newImage,
                        newImage: nextProps.src,
                        current: prevState.current + 1,
                        loaded: false
                    }
                }
            }
        }
        else return null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
        // return !(nextProps.src === this.props.src || this.props.src === null);
    }

    componentDidMount() { let self = this;

        if(this.ref1){

            this.animation = new animationsState();
            this.animation._init().then(() => {
                self.animation.initAnimationImage();

                if(this.state.current % 2 === 0) {
                    self.animation.animationImage(this.ref1, this.ref2, 0)
                } else {
                    self.animation.animationImage(this.ref2, this.ref1, 0);
                }

            });
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) { let that = this;

        if(prevState.oldImage){
            if(prevState.newImage !== this.state.newImage){

                if(this.state.current % 2 === 0){
                    if(!this.state.newImage.includes("blank")) {
                        that.animation.animationImage(that.ref1, that.ref2, 0.6);
                    } else {
                        that.animation.animationImage(that.ref1, that.ref2, 0);
                    }
                } else {
                    if(!this.state.newImage.includes("blank")) {
                        that.animation.animationImage(that.ref2, that.ref1, 0.6);
                    } else {
                        that.animation.animationImage(that.ref2, that.ref1, 0);
                    }
                }

            }
        }

    }

    setRef(number, ref){
        if(number === 1){
            this.props.setRef1(ref); this.ref1 = ref;
        } else if(number === 2){
            this.props.setRef2(ref); this.ref2 = ref;
        }
    }

    handleImageLoaded = () => { let that = this;
        this.setState(
            {
                loaded: true,
                firstLoad: true
            }
        );

        if(this.state.current % 2 === 0){
            if(!this.state.newImage.includes("blank")) {
                animations.animationViewImageInAsync(that.ref1, 0.6);
            } else {
                animations.animationViewImageInAsync(that.ref1, 0);
            }
        } else {
            if(!this.state.newImage.includes("blank")) {
                animations.animationViewImageInAsync(that.ref2, 0.6);
            } else {
                animations.animationViewImageInAsync(that.ref2, 0);
            }
        }

    };

    render() {

        const { src, loading, setRefFx, setRef1, setImageRef, setRef2, couvertureViewImage1 } = this.props;


        const classes = classNames({
            'ready': this.state.loaded,
            'not-ready': !this.state.loaded,
            'inner-head': true,
        });

        const classes2 = classNames({
            'loaded': loading,
            'fuck': true
        });

        let src1 = this.state.oldImage;
        let src2 = this.state.oldImage;

        if(this.state.current % 2 === 0){
            src1 = this.state.newImage;
        } else {
            src2 = this.state.newImage;
        }

        const classesImage01 = classNames({
            'image-01': true,
            'active': this.state.current % 2 === 0
        });

        const classesImage02 = classNames({
            'image-02': true,
            'active': this.state.current % 2 !== 0
        });

        return(
            <div className={ classes } data-text="true">
                { /*this.state.firstLoad && this.state.newImage && !this.state.newImage.includes("blank") && <div className="fx-img" ref={ (ref) => setRefFx(ref) } /> */}
                {this.state.newImage && !this.state.newImage.includes("blank") && <div className="fx-img" ref={ (ref) => setRefFx(ref) } />}
                <div className="imageView" ref={ (ref) => setImageRef(ref) }>

                    <ProgressiveContainerImage
                        classe={ classesImage01 }
                        src={ src1 }
                        number={ 1 }
                        setRef={ this.setRef }
                        handleImageLoaded={ this.handleImageLoaded }
                    />

                    <ProgressiveContainerImage
                        classe={ classesImage02 }
                        src={ src2 }
                        number={ 2 }
                        setRef={ this.setRef }
                        handleImageLoaded={ this.handleImageLoaded}
                    />

                </div>
            </div>
        )
    }

}

class ImageWithStatusText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            imageStatus: "loading"
        };
    }

    handleImageErrored() {
    }

    componentDidMount() {
        //this.props.handleImageLoaded();
    }

    componentDidUpdate() {
        //this.props.handleImageLoaded();
    }

    render() {

        const classes2 = classNames({
            'loaded': this.props.loaded,
        });

        return (
            <img
                className={ classes2 }
                src={ this.props.imageUrl }
                onError={ this.handleImageErrored.bind(this) }
                ref={ (ref) => {
                    this.props.setRef(this.props.number, ref);
                }}
            />
        );
    }
}

export class CibleView extends Component {

    constructor(props){
        super(props);

        this.state = {
            numero: JSON.parse(JSON.stringify(this.props.numero)),
            article: JSON.parse(JSON.stringify(this.props.article)),
            fonts: JSON.parse(JSON.stringify(this.props.fonts)),
            isMounted: false
        };

        this.couvertureViewImage1 = null;
        this.couvertureViewImage2 = null;
        this.couvertureViewImage = null;
        this.couvertureTitleView = null;
        this.couvertureViewBtn = null;

        this.setImageRef1 = this.setImageRef1.bind(this);
        this.setImageRef2 = this.setImageRef2.bind(this);
        this.setImageRef = this.setImageRef.bind(this);
        this.setRefFx    = this.setRefFx.bind(this);

    }

    static getDerivedStateFromProps(props, state) {
        if (props.article._id !== state.article._id || props.article.isRead !== state.article.isRead) {
            return {
                article: props.article,
                numero: props.numero,
                fonts: props.fonts
            };
        }

        return null;
    }

    animateTitle() {
        animations.CouvertureViewTitle(this.couvertureTitleView);
    }

    animate(isMounting = false) {

        let yAnim = -200;

        if(typeof window !== "undefined" && clientWidth < 426) {
            yAnim = -70;
        }

        if(typeof window !== "undefined" && clientWidth < 321) {
            yAnim = -40;
        }

        if (this.props.view === "couverture") {
            if(isMounting){
                animations.CouvertureViewCouverture(this.couvertureView, 0);
                animations.CouvertureViewImageCouverture(this.couvertureViewImage, 0);
            } else {
                animations.CouvertureViewCouverture(this.couvertureView);
                animations.CouvertureViewImageCouverture(this.couvertureViewImage);
            }
        } else if (this.props.view === "article") {
            if(isMounting){
                animations.CouvertureViewArticle(this.couvertureView, yAnim, 0);
                animations.CouvertureViewImageArticle(this.couvertureViewImage, 0, 0);
            } else {
                animations.CouvertureViewArticle(this.couvertureView, yAnim);
                animations.CouvertureViewImageArticle(this.couvertureViewImage, 0);
            }
        }

    }

    componentDidMount() {
        this.animate(true);
        animations.CouvertureViewTitle(this.couvertureTitleView, 0);
        animations.setCouvertureViewBtn(this.couvertureViewBtn, this.props.view);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.view !== this.props.view) {
            this.animate();
            this.animateTitle();
            animations.CouvertureViewBtn(this.couvertureViewBtn, this.props.view);
        }
        this.props.changeKindView(this.props.view);
    }

    setImageRef1(ref) {
        this.couvertureViewImage1 = ref;
    };

    setImageRef(ref) {
        this.couvertureViewImage = ref;
    };

    setImageRef2(ref) {
        this.couvertureViewImage2 = ref;
    };

    setRefFx(ref) {
        this.couvertureViewImageFX = ref;
    };

    getNumeroInNumeros(numeroObj, numeros) {
        return numeros.filter((numero) => numero._id === numeroObj._id)
    }

    openLink = (link, e) => {
        e.preventDefault();

        this.props.startRead();

        this.props.history.push(link);
    };

    getToLastRead = (article, numero, e) => {

        e.preventDefault();

        this.props.continueRead();

        const lastNumero = this.props.client.readQuery({ query: GET_CURRENT_NUMERO });

        if(lastNumero.currentNumero._id === article.numero._id) {
            this.props.history.push(`/${article.numero.slug}/${article.category.slug}/${article.slug}`);
            return null;
        }

        let currentNumeroObj = this.getNumeroInNumeros(article.numero, this.props.numeros)[0];

        this.props.client.writeQuery({
            query: UPDATE_CURRENT_NUMERO,
            data: {
                currentNumero: currentNumeroObj
            }
        });

        this.props.history.push(`/${article.numero.slug}/${article.category.slug}/${article.slug}`);

    };

    openCouv = (link, e) => {
        e.preventDefault();

        this.props.captureCouvRead();
        this.props.history.push(link);

    };

    getRatioHeight(article) {
        if(typeof window !== "undefined") {
            if (this.state.article.wallpaper.url) {
                let full = document.body.getBoundingClientRect().width;
                let format = article.custom.image.format;
                if (!format.height) return ((420 * full) / 1280) + "px";
                return ((format.height * full) / format.width) + "px";
            } else {
                return '600px';
            }
        } else {
            return "620px";
        }
    }

    getImageCible() {

        let importBlank = null;
        if(typeof blank !== "object"){
            importBlank = blank;
        }

        let articleCouv = (this.state.article.wallpaper.url) ?
            this.state.article.wallpaper.url : importBlank;

        if(this.props.view === "couverture"){
            if(this.props.numero.custom.couverture){
                if(this.props.numero.custom.couverture.image){
                    return this.props.numero.custom.couverture.image;
                } else {
                    return articleCouv
                }
            } else {
                return articleCouv
            }
        } else {
            return articleCouv
        }
    }

    getInfosCible = () => {
        return (
            <div className="infos" key={ this.state.article._id }>
                <div className="chapitre"   data-text="true">

                    <div className="category-block">
                        <div className="number"
                             style={{ backgroundColor: '#F97C15' }}>
                            { this.state.article.category.number }
                        </div>
                        <div className="inner-titles">
                            <span>{ this.state.article.category.title }</span>
                            {this.state.article.custom.sous_chapitre &&
                                <span className="subcategory" style={ this.state.fonts.family3 }
                                >{ this.state.article.custom.sous_chapitre.title }</span>
                            }
                        </div>
                    </div>

                    <div className="outer-i">
                        <div className="time" style={ this.state.fonts.family3 }>
                            <i className="icon-time" />
                            <span className="inner">
                               { this.state.article.timeForRead }mn
                            </span>
                        </div>
                        <IsRead
                            unsetArticleReaded={ this.props.unsetArticleReaded }
                            setArticleReaded={ this.props.setArticleReaded }
                            article={ this.state.article }
                            fonts={ this.state.fonts }
                            user={ this.props.user }
                            isCouverture={ true } />
                    </div>
                </div>
            </div>
        )
    };

    getHeaderCible = () => {
        if(this.props.view === "couverture") {

            if(!this.state.numero.custom.couverture) {
                return [
                    this.getInfosCible(),
                    <h1 style={ this.state.fonts.family1 } data-text="true" key={ this.state.article._id + "0"}>
                        {this.state.article.title}
                    </h1>,
                ]
            }

            if(!this.state.numero.custom.couverture.titre_personnalise) {
                return [
                    this.getInfosCible(),
                    <h1 style={ this.state.fonts.family1 } data-text="true" key={ this.state.article._id + "1"}>
                        {this.state.article.title}
                    </h1>,
                ]
            } else {
                return(
                    <h1
                        style={ this.state.fonts.family1 } data-text="true" className="title"
                        dangerouslySetInnerHTML={{ __html:this.state.numero.custom.couverture.titre_personnalise}} />
                )
            }
        } else {
            return [
                this.getInfosCible(),
                <h1 style={ this.state.fonts.family1 } data-text="true" key={ this.state.article._id + "_h1"}>
                    {this.state.article.title}
                </h1>,
            ]
        }
    };


    lastArticleRead = () => {
        return this.props.user.lastArticlesRead.find((obj) => {
            return (this.state.numero._id === obj.numero._id);
        });
    };

    openLinkAndLoading = (link, e) => {
        e.preventDefault();
        // this.props.toggleMenu(false);
        this.props.history.push(link);
        //this.props.toggleLoadingOn();
    };


    render() {

        let lastArticleRead = this.lastArticleRead();

        let style = {
            height: this.getRatioHeight(this.state.article)
        };

        let image = this.getImageCible();

        return(
            <div className={ this.props.classes } ref={(ref) => this.couvertureView = ref} style={ style }>
                <div className="article-couverture">

                    <div className="inner">
                        <Link to={`/${this.state.numero.slug}/${this.state.article.category.slug}/${this.state.article.slug}`}
                              onClick={(e) =>
                                  this.openCouv(`/${this.state.numero.slug}/${this.state.article.category.slug}/${this.state.article.slug}`, e) }>
                            <div ref={ (ref) => this.couvertureTitleView = ref }>
                                { this.getHeaderCible() }
                            </div>
                        </Link>
                    </div>

                    <ImageComponentOnLoad image={ image } />
                    {/*<ProgressiveContainer
                        src={ image }
                                          setRef1={this.setImageRef1}
                                          setRef2={this.setImageRef2}
                                          setRefFx={this.setRefFx}
                                          setImageRef={this.setImageRef}
                                          couvertureViewImage={this.couvertureViewImage}
                                          couvertureViewImage1={this.couvertureViewImage1}
                                          couvertureViewImage2={this.couvertureViewImage2}
                    />*/}

                    { lastArticleRead &&
                        <Link to={`/${lastArticleRead.numero.slug}/${lastArticleRead.category.slug}/${lastArticleRead.slug}`}
                           onClick={(e) => this.getToLastRead(lastArticleRead, this.props.numeros, e) }
                            className="start-btn" style={ this.state.fonts.family1 } data-text="true">
                            <div ref={(ref) => this.couvertureViewBtn = ref}>
                                    <span className="inner-start">
                                        Reprendre la lecture
                                        <i className="ico-pold-start-reading" />
                                    </span>
                            </div>
                        </Link>
                    }

                    { !lastArticleRead &&
                        <Link to={`/${this.state.numero.slug}/${this.props.firstRead.category.slug}/${this.props.firstRead.slug}`}
                              onClick={ (e) =>
                                  this.openLink(`/${this.state.numero.slug}/${this.props.firstRead.category.slug}/${this.props.firstRead.slug}`,e)
                              } className="start-btn" style={ this.state.fonts.family1 } data-text="true">
                            <div ref={(ref) => this.couvertureViewBtn = ref}>
                                    <span className="inner-start">
                                        Commencer la lecture
                                        <i className="ico-pold-start-reading" />
                                    </span>
                            </div>
                        </Link>
                    }

                    {/*<ExportPDFBtn match={ this.props.match }
                                  client={ this.props.client }
                                  magazine={ this.props.magazine }
                                  user={ this.props.user }
                                  article={ this.state.article}
                                  currentNumero={ this.state.numero }
                                  className="imprimer-btn"
                    />*/}

                    {/*<ExportToPdfAsync numero={ this.state.numero }
                                      magazine={ this.props.magazine }
                                      article={ this.state.article }
                    />*/}

                </div>
            </div>
        )
    }



}

class ImageComponentOnLoad extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            imageStatus: false
        };
    }

    handleImageLoaded() {
        this.setState({ imageStatus: true });
    }

    handleImageErrored() {
        this.setState({imageStatus: false});
    }

    componentDidUpdate(prevProps) {
        if(prevProps.image !== this.props.image){
            this.setState({
                imageStatus: false
            })
        }
    };

    render() {

        let classes = classNames({
            'loaded': this.state.imageStatus
        });

        let classesFx = classNames({
            'fx-img': true,
            'loaded': this.state.imageStatus
        });

        return[
            <img className={ classes }
                src={this.props.image}
                onLoad={this.handleImageLoaded.bind(this)}
                onError={this.handleImageErrored.bind(this)}
            />,
            <div className={ classesFx } />
        ]
    }

}

class IsRead extends Component {

    articleIsRead(user, article) {
        if(user.articles) {
            return user.articles.some((articleObj) => {
                return (articleObj._id === article._id && articleObj.readed);
            });
        }
    }

    setRead = (article) => {
        if(this.props.isCouverture)
            return this.props.setArticleReaded(article)
    };

    unsetRead = (article) => {
        if(this.props.isCouverture)
            return this.props.unsetArticleReaded(article)
    };

    render() {

        const { article, fonts, user, isCouverture } = this.props;

        if(this.articleIsRead(user, article)){
            return(
                <div className="status"
                     style={ fonts.family3 } onClick={() => this.unsetRead(article) }>
                    <i className="ico-pold-lu" />
                    <span className="inner">Lu</span>
                </div>
            )
        } else {
            return(
                <div className="status"
                     style={ fonts.family3 } onClick={() => this.setRead(article) }>
                    <i className="ico-pold-non_lu" />
                    <span className="inner">Non lu</span>
                </div>
            )
        }
    }

}

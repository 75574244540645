import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Query } from "react-apollo";
import classNames from "classnames";

import { SettingsContext } from "../../../../Magazine/MagazineSettings";
import { GET_CURRENT_NUMERO } from "../../../../../Queries";
import { withRouter } from "react-router-dom";
import Socials from "./Socials";

// import Logo from "./assets/byridmi.svg";

let FontAwesomeIcon;

const Loading = () => null;

const NumeroInfo = ({ numero, fonts }) => {

    let date = new Date(numero.date);
    let dateParsed = Date.parse(numero.date);

    let options = { year: 'numeric', month: 'long', day: 'numeric' };

    return (
        <div className="numeroInfo">
            <span className="date" data-text="true" style={ fonts.family3 }></span>
            <span className="number" data-text="true" style={ fonts.family3 }>Rapports annuels</span>
            <a href="https://www.ridmi.io" target="_blank" className="ridmi-link">
                <div className="logo" />
            </a>
        </div>
    )
};

const MenuIconOn = () => {

    return(<div>
        <svg width="86px" height="86px" viewBox="0 0 86 86" className="menu-icon-on">
            <g istroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(12.000000, 28.000000)" fill="#FFFFFF">
                    <g>
                        <g>
                            <rect id="Rectangle-2" x="0" y="0" width="46" height="5"></rect>
                            <rect id="Rectangle-2-Copy" x="0" y="12" width="46" height="5"></rect>
                            <rect id="Rectangle-2-Copy" x="0" y="12" width="46" height="5"></rect>
                            <rect id="Rectangle-2-Copy-2" x="0" y="25" width="30" height="5"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </div>)


};

const MenuIconOff = () => {

    return(<div>
        <svg width="36px" height="37px" viewBox="0 0 36 37" className="menu-icon-off">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-33.000000, -26.000000)" fill="#FFFFFF">
                    <g>
                        <g transform="translate(32.000000, 26.000000)">
                            <rect id="Rectangle-2" transform="translate(19.000000, 18.500000) rotate(-315.000000) translate(-19.000000, -18.500000) " x="-4" y="16" width="46" height="5" />
                            <rect id="Rectangle-2-Copy" transform="translate(19.000000, 18.500000) rotate(-45.000000) translate(-19.000000, -18.500000) " x="-4" y="16" width="46" height="5" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        </div>
    );


};

class LateralBarView extends Component {

    render() {

        const { scrolled, socials, numero, toggleMenu, menuShow, toggleMenuShow, fonts, isPage, logo, noImg } = this.props;

        const classes = classNames({
            'menuShow': menuShow,
            'menu-toggle-btn': true
        });

        const classesLateral = classNames({
            'menuShow': menuShow,
            'lateralBarView': true,
            'isSearching': this.props.search,
            'scrolled': scrolled || isPage || noImg
        });

        return (
            <div className={ classesLateral }>
                <button className={ classes } onClick={ () => { toggleMenu(); toggleMenuShow() } }>
                    <MenuIconOn />
                </button>
                <div className="menu">
                </div>
                <div className="numero-lateral">
                    <div className="numero-info">
                        <NumeroInfo numero={ numero } fonts={ fonts } menuShow={ menuShow }/>
                    </div>
                    <div className="numero-socials">
                        <Socials socials={ socials } logo={ logo }/>
                    </div>
                </div>
            </div>
        )


    }
}


class LateralBar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            menuShow: this.props.menuShow
        }
    }

    toggleMenuShow = () => {

        this.setState((prevState) => {
            return {
                menuShow: !prevState.menuShow
            }
        });

        if(this.state.menuShow){
            this.props.captureMenuClick('close');
        } else {
            this.props.captureMenuClick('open');
        }

    };

    render() { let that = this;
        return (
            <Query query={ GET_CURRENT_NUMERO }>
                {({loading, error, data, client}) => {

                    if(!data.currentNumero) that.forceUpdate();

                    if (loading) return null;
                    if (error) return `Error! ${error.message}`;

                    return (
                        <SettingsContext.Consumer>
                            {({ couleur, fonts, socials }) => {

                                return(
                                    <LateralBarView
                                        socials={ socials }
                                        numero={ data.currentNumero }
                                        noImg={ this.props.noImg }
                                        couleur={ couleur }
                                        isPage={ this.props.isPage }
                                        toggleMenu={ this.props.toggleMenu }
                                        menuShow={ this.props.menuShow }
                                        search={ this.props.search }
                                        toggleMenuShow={ this.toggleMenuShow }
                                        scrolled={ this.props.scrolled }
                                        fonts={ fonts }
                                    />
                                )
                            }}
                        </SettingsContext.Consumer>
                    )
                }}
            </Query>
        )
    }
}

const mapStateToProps = (state, props) => {

    return {
        scrolled: state.scrolled.scrolled,
        search: state.search.isSearching,
        view: state.view.kind,
        noImg: state.view.noImg
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        captureMenuClick: (value) => {
            dispatch({
                type: "CLICK_MENU",
                value: value
            })
        }
    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LateralBar));

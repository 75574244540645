import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from "react-redux";

import "./rgpd.scss";
import cookie from "./cookies.png";
import classNames from "classnames";
import Loadable from 'react-loadable';

import { SettingsContext } from "../../../Magazine/MagazineSettings";
import {Link, withRouter} from "react-router-dom";

class RGPD extends Component {

    constructor(props){
        super(props);
        this.state = {
            settings: null,
        }
    }

    componentDidMount() {

        let props = JSON.parse(JSON.stringify( this.props.settings ));

        this.setState({
            settings: props
        })
    }

    acceptAllFn = (confirm = false) => {

        this.setState(state => {

            let categories = this.state.settings.categories
                .map((category) => {
                    category.services.map((service) => {
                        service.accepted = true;
                        return service;
                    });
                    return category;
                });

            return {
                settings: {
                    ...this.state.settings,
                    categories: categories
                }
            };

        });

        if(confirm) {
            this.saveChanges();
        }

    };

    refuseAllFn = (confirm = false) => {

        this.setState(state => {

            let categories = this.state.settings.categories
                .map((category) => {
                    category.services.map((service) => {
                        service.accepted = false;
                        return service;
                    });
                    return category;
                });

            return {
                settings: {
                    ...this.state.settings,
                    categories: categories
                }
            }

        });

        if(confirm) {
            this.saveChanges();
        }

    };

    saveChanges = () => {

        this.setState({
            settings: {
                ...this.state.settings,
                interaction: true
            }
        });

        this.props.changeSettings(this.state.settings);
    };

    toggleSettingValue = (categorySlug, serviceSlug, value) => {

        this.setState(state => {

            let categories = this.state.settings.categories
                .map((category) => {
                    if(category.slug === categorySlug){
                        category.services.map((service) => {
                            if(service.slug === serviceSlug){
                                service.accepted = value;
                            }
                            return service;
                        })
                    }
                    return category;
                });

            return {
                settings: {
                    ...this.state.settings,
                    categories: categories
                }
            }
        });

    };


    render(){

        if(typeof document !== "undefined") {
            if(this.state.settings) {
                if(this.props.window){
                    return ReactDOM.createPortal(
                        <RGPDView rgpd={ this.props.rgpd }
                                  settings={this.state.settings}
                                  SwitchiOSComponent={ SwitchiOSComponent }
                                  toggleMenu={ this.props.toggleMenu }
                                  toggleSettingValue={this.toggleSettingValue}
                                  history={ this.props.history }
                                  saveChanges={ this.saveChanges }
                                  acceptAllFn={ this.acceptAllFn }
                                  refuseAllFn={ this.refuseAllFn }
                                  closeRGPDPopup={ this.props.closeRGPDPopup }
                        />,
                        document.getElementById("rgpd-portal")
                    );
                } return null;
            } return null;
        } return null;

    }

}

export class RGPDView extends Component {

    constructor(props){
        super(props);

        this.state = {
            hide: true,
            hidePopup: true,
            view: "message"
        }

    }

    hide(reverse = false){ let that = this;

        setTimeout(function() {

            setTimeout(function() {
                that.setState({
                    hide: reverse,
                });
            }, (!reverse) ? 0 : 800);
            setTimeout(function(){
                that.setState({
                    hidePopup: reverse
                });
            }, 10);
        }, (!reverse) ? 800 : 0);

    }

    hideWithoutWait(reverse){  let that = this;

        that.setState({
            hide: reverse,
        });

        setTimeout(function(){
            that.setState({
                hidePopup: reverse
            });
        }, 10);

    }

    closeView = () => { let that = this;

        this.hide(true);

        setTimeout(function() {
            that.props.closeRGPDPopup();
        }, 520);

    };

    rejectAll = (hide = false) => { let that = this;
        if(hide) this.hide(true);

        setTimeout(function() {
            if(hide){
                that.props.refuseAllFn(true);
            } else {
                that.props.refuseAllFn();
            }
        }, 520);

    };

    acceptAll = (hide = false) => { let that = this;
        if(hide) this.hide(true);

        setTimeout(function() {
            if(hide){
                that.props.acceptAllFn(true);
            } else {
                that.props.acceptAllFn();
            }
        }, 520);

    };

    saveChangesView = () => { let that = this;

        this.hide(true);

        setTimeout(function() {
            that.props.saveChanges();
        }, 500);
    };

    changeView = (what) => {
        this.setState({
            view: what
        })
    };

    render() {

        const classes = classNames({
            'hide': this.state.hidePopup,
            'show': !this.state.hidePopup,
            'rgpd-outer': true,
            'already-interact': this.props.rgpd.settings.interaction
        });

        if(this.state.hide) {
            if(this.props.rgpd.settings.interaction){
                this.hideWithoutWait();
                this.setState({
                    view: "settings"
                })
            } else {
                this.hide();
            }
        }

        if(!this.state.hide) {
            return (
                <SettingsContext.Consumer>
                {({ fonts }) => (
                    <div className={ classes }>
                    <div className="rgpd-inner">

                        {this.props.rgpd.settings.interaction &&
                            <button className="close" onClick={ this.closeView }>
                                close
                            </button>
                        }

                        {this.state.view === 'message' &&
                        <div className="rgpd-message">
                            <div className="inner">
                                <div className="header">
                                    <div className="title" style={ fonts.family1 }>
                                        <img src={ cookie } alt=""/>
                                        <span>Cookies</span>
                                    </div>
                                </div>
                                <div className="main">
                                    <p style={ fonts.family4 }>
                                        Nous collectons des cookies afin d’améliorer votre expérience utilisateur. En aucun cas nous diffusons ces données. Est-ce que cela vous convient ? {/*Consultez nos <Link to={ `/mentions-legales`}>Mentions Légales</Link> ou <button onClick={ () => this.changeView("settings") }>personalisez vos préférences</button>.*/}
                                    </p>
                                </div>
                                <div className="footer">

                                    <div className="accept-btns">
                                        <div className="btn accept" style={ fonts.family4 } onClick={ () => this.acceptAll(true) }>Oui</div>
                                        <div className="btn reject" style={ fonts.family4 } onClick={ () => this.changeView("settings") }>Personnaliser</div>
                                    </div>

                                </div>
                            </div>
                        </div>}

                        {this.state.view === 'settings' &&
                            <RGPDsettings changeView={ this.changeView }
                                          acceptAll={ this.acceptAll }
                                          rgpd={ this.props.rgpd }
                                          toggleMenu={ this.props.toggleMenu }
                                          closeView={ this.closeView }
                                          history={ this.props.history }
                                          interaction={ this.props.rgpd.settings.interaction }
                                          fonts={ fonts }
                                          rejectAll={ this.rejectAll }
                                          SwitchiOSComponent={ this.props.SwitchiOSComponent }
                                          settings={ this.props.settings }
                                          toggleSettingValue={ this.props.toggleSettingValue }
                                          saveChanges={ this.saveChangesView }
                            />
                        }

                    </div>
                </div>
                )}
                </SettingsContext.Consumer>
            )
        } else return null;
    }


}

export class RGPDsettingsView extends Component {

    goTo = (e, link) => {
        e.preventDefault();

        if(this.props.interaction) {
            this.props.toggleMenu(false);
            this.props.closeView()
        } else {
            this.props.toggleMenu(false);
            this.props.changeView("message")
        }

        this.props.history.push(link)
    };

    render() { let that = this;

        return(
            <div className="rgpd-settings">
                <div className="inner">
                    <div className="header">
                        <div className="title" style={ this.props.fonts.family1 }>
                            <img src={ cookie } alt=""/>
                            <span>Préférences cookies</span>
                        </div>
                        <div className="main">
                            <p style={ this.props.fonts.family4 }>
                                Nous utilisons des cookies pour améliorer votre expérience et vous proposer du contenu qui vous ressemble plus. Cela vous convient ? Vous pouvez aussi consulter nos <Link to={ `/mentions-legales` } onClick={
                                    (e) => this.goTo(e, `/mentions-legales`)
                                }>Mentions Légales</Link>.
                            </p>
                        </div>
                        {/*<div className="accept-btns">*/}
                            {/*<div className="btn accept" style={ this.props.fonts.family1 } onClick={ () => this.props.acceptAll() }>Je suis d'accord</div>*/}
                            {/*<div className="btn reject" style={ this.props.fonts.family1 } onClick={ () => this.props.rejectAll() }>Je ne suis pas d'accord</div>*/}
                        {/*</div>*/}
                    </div>
                    <div className="main">
                        {this.props.settings.categories.map((category) => {
                            return(
                                <div className="category-rgpd">
                                    <div className="title" style={ this.props.fonts.family4 }>{ category.title }</div>
                                    <div className="services">
                                        {category.services.map((service) => {
                                            return <Service service={ service }
                                                            category={ category }
                                                            fonts={ this.props.fonts }
                                                            SwitchiOSComponent={ this.props.SwitchiOSComponent }
                                                            key={ service.id }
                                                            toggleSettingValue={ that.props.toggleSettingValue }

                                            />
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="footer">
                        <div className="btn" style={ this.props.fonts.family4 } onClick={ that.props.saveChanges }>Valider</div>
                        <div className="navigation">
                            {!this.props.interaction &&
                                <button className="back btn" style={ this.props.fonts.family4 }
                                        onClick={ () => this.props.changeView("message") }>Quitter sans valider</button>
                            }
                            {this.props.interaction &&
                                <button className="back btn" style={ this.props.fonts.family4 }
                                        onClick={ () => this.props.closeView() }>Quitter sans valider</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

const loadingComponent = () => {
    return (
        <div className="loading-component" />
    )
};

const SwitchiOSComponent = Loadable({
    loader: () => import(/* webpackChunkName: "SwitchiOSComponent" */ 'react-ios-switch'),
    loading: loadingComponent,
    modules: ['SwitchiOSComponent']
});


class Service extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        return(
            <div className="service">
                <div className="details" style={ this.props.fonts.family4}>
                    <div className="title">
                        { this.props.service.title }
                    </div>
                    <div className="desc">
                        { this.props.service.desc }
                    </div>
                </div>
                <div className="activate">
                    <this.props.SwitchiOSComponent
                        checked={ this.props.service.accepted }
                        onColor="rgb(47, 189, 218)"
                        onChange={ checked => {
                            this.props.toggleSettingValue(this.props.category.slug, this.props.service.slug, checked);
                        }}
                    />
                    {/*<span className="value">*/}
                        {/*{ this.props.service.accepted && "on"}*/}
                        {/*{ !this.props.service.accepted && "off"}*/}
                    {/*</span>*/}
                </div>
            </div>
        )

    }

}


export class RGPDsettings extends Component {

    constructor(props){
        super(props);
    }

    render() {
        return(
            <RGPDsettingsView
                settings={ this.props.settings }
                fonts={ this.props.fonts }
                changeView={ this.props.changeView }
                closeView={ this.props.closeView }
                toggleMenu={ this.props.toggleMenu }
                interaction={ this.props.interaction }
                history={ this.props.history }
                SwitchiOSComponent={ this.props.SwitchiOSComponent }
                acceptAll={ this.props.acceptAll }
                rejectAll={ this.props.rejectAll }
                toggleSettingValue={ this.props.toggleSettingValue }
                saveChanges={ this.props.saveChanges }
            />
        )
    }


}

const mapStateToProps = (state, props) => {

    return {
        rgpd: state.rgpd,
        window: state.rgpd.window,
        store: state,
        settings: state.rgpd.settings
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeSettings: (settings) => {
            return dispatch({
                type: 'CHANGE_SETTINGS',
                settings: settings
            })
        },
        closeRGPDPopup: () => {
            return dispatch({
                type: 'CLOSE_WINDOW_RGPD'
            })
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RGPD));

import React, { Component } from 'react';
import { connect } from 'react-redux';
import CouvertureView from './views/CouvertureView';
import { GET_CURRENT_NUMERO } from "../../../../Queries";
import { Query } from "react-apollo";
import PropTypes from "prop-types";
import animations from "./animations.js";
import { withRouter } from "react-router-dom";

class Couverture extends Component {

    constructor(props) {
        super(props);

        this.state = {
            warnings : [
               "Veuillez ajouter un article à la première catégorie",
               "Veuillez ajouter des catégories à la publication"
            ],
            heightCouverture: "100vh",
            ref: null
        };

        this.handleScroll = this.handleScroll.bind(this);

    }

    setReference = (ref) => {
        this.ref = ref;
    };

    getFirstArticleToRead(data) {

        const categoriesArr = data.currentNumero.categories.slice(0);

        if(categoriesArr.length === 0) {
            return {
                error: true,
                warning: this.state.warnings[1]
            }
        }

        const category = categoriesArr.sort((a, b) => {
            return (a.order - b.order)
        })[0];

        let article = data.currentNumero.articles.find((article) => article.category._id === category._id);

        if(!article) {
            return {
                error: true,
                warning: this.state.warnings[0]
            }
        } else {
            return {
                error: false,
                article
            }
        }

    }

    setHeightCouverture() {

        let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

        if(iOS){
            //let heightCouverture = require('ios-inner-height');
            let heightCouverture = 0;
            let newHeight = heightCouverture + "px";

            this.setState({
                heightCouverture: window.innerHeight
            });
        }

    }

    handleScroll() {
        this.setHeightCouverture();
    }

    componentWillUnmount() {

        if(this.ref){
            animations.couvertureArticlesLeave(this.ref);
        }

        window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidMount() {

        this.setHeightCouverture();

        if(this.ref){
            animations.couvertureArticlesEnter(this.ref);
        }

        window.addEventListener('scroll', this.handleScroll);

    }

    getArticleCouv(data) {

        const articles = data.currentNumero.articles;
        const couverture = data.currentNumero.couverture;
        let firstArticle = {};
        let others = [];

        couverture.forEach((articleId, index) => {
            if(index === 0){
                firstArticle = articles.find(( article) => article._id === articleId);
            }
            else others.push(articles.find(( article) => article._id === articleId));
        });

        return {
            firstArticle,
            others
        }

    }

    captureStartRead = () => {

        this.props.store.dispatch({
            type: 'COMMENCER_LECTURE'
        })

    };

    render() { const { magazine } = this.props; let that = this;
        return (
            <Query query={ GET_CURRENT_NUMERO }>
                {({loading, error, data, client}) => {

                    if(!data.currentNumero) {
                        that.forceUpdate();
                    }

                    if (loading) return "Loading GET_CURRENT_NUMERO couverture...";
                    if (error) return `Error! ${error.message}`;

                    let articles = data.currentNumero.articles;
                    if(!articles) return <div className='warning'> { this.state.warnings[0] } </div>;

                    let firstRead = this.getFirstArticleToRead(data);

                    if(!firstRead.error)
                        return (
                            <div ref={ this.setReference }>
                                <CouvertureView
                                    magazine={ this.context.magazine }
                                    numero={ data.currentNumero }
                                    match={ this.props.match }
                                    couverture={ this.getArticleCouv(data) }
                                    user={ this.props.user }
                                    firstRead={ firstRead.article }
                                    heightCouverture={ this.state.heightCouverture }
                                    articles={ articles }
                                    captureStartRead={ this.props.startRead }
                                    captureCouvRead={ this.props.captureCouvRead }
                                    captureEntreeRead={ this.props.captureEntreeRead }
                                />
                            </div>
                        );
                    else
                        return <div className='warning'> { firstRead.warning } </div>
                }}
            </Query>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        magazine: state.magazine,
        user: state.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return({
        startRead: () => {
            dispatch({
                type: 'COMMENCER_LECTURE'
            });
        },
        captureCouvRead: () => {
            dispatch({
                type: 'CLICK_LECTURE_UNE'
            });
        },
        captureEntreeRead: (number) => {

            dispatch({
                type: 'CLICK_ENTREE_COUV',
                number
            });
        },
        changeCurrentArticle: () => {
            dispatch({
                type: 'CHANGE_CURRENT_ARTICLE',
                currentArticle: null,
                view: "couverture"
            });
        },
    })
};

Couverture.contextTypes = {
    magazine: PropTypes.object
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Couverture));

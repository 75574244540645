import React, { Component } from 'react';
import { Link } from "react-router-dom";
import classNames from "classnames";
import 'react-perfect-scrollbar/dist/css/styles.css';

import { SettingsContext } from "../../../../../Magazine/MagazineSettings";
import { GET_CURRENT_NUMERO, GET_MENU_NUMEROS } from "../../../../../../Queries";
import { Query } from "react-apollo";
import PropTypes from "prop-types";

import SearchBtn from "./SearchBtn";

import { ScrollContext } from "../../../../../Scroll/Scroll";
import { Accessibilite } from "../../../Accessibilite/Accessibilite";
import Loadable from "react-loadable";

const Loading = () => null;

const ExportToPdfAsync = Loadable({
    loader: () => import('../../../../../Functionalities/ExportToPdfAsync/ExportToPdfAsync'),
    loading: Loading
});

let supportsTouch, clientWidth;

if(typeof window !== "undefined"){
    supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

    clientWidth = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
}

let PerfectScrollbar;

function truncate(input, length) {
    if(!input) return "";
    if (input.length > length)
        return input.substring(0, length) + '...';
    else
        return input;
}

class NumeroMenu extends Component {

    constructor(props){
        super(props);

        this.state = {
            menuShow: false,
            isPerfect: false
        }

    }

    toggleNumeroMenu = (toggle = null, numero = null) => {

        if(toggle === false)
            this.setState(() => {
                return {
                    menuShow: false
                }
            });
        else {
            this.setState((prevState) => {
                return {
                    menuShow: !prevState.menuShow
                }
            })
        }
    };

    componentDidMount() {
        // if(typeof window !== "undefined"){
        //     let react_perfect_scrollbar = require('react-perfect-scrollbar');
        //     PerfectScrollbar = react_perfect_scrollbar.default;
        //     this.setState({
        //         isPerfect: true
        //     });
        // }
    }

    fetchMore(){

        this.props.fetchMore({
            variables: {
                offset: this.props.numeros.length
            },
            updateQuery: (prev, { fetchMoreResult }) => {

                if (!fetchMoreResult) return prev;

                return Object.assign({}, prev, {
                    numeros: [...prev.numeros, ...fetchMoreResult.numeros]
                });

            }
        });

    }

    getCurrentNumero(numeros, current) {
        return numeros.find((numero) => numero._id === current._id)
    }

    goTo = (e, link, numero, current) => {
        e.preventDefault();

        if(current._id !== numero._id) {
            this.props.toggleMenu(false, numero);
        } else {
            this.props.toggleSearch();
        }

    };

    render() {

        let length = this.props.numeros.length;

        const classes = classNames({
            'menu-numero': true,
            'menuShow': this.state.menuShow,
        });

        return(
            <SettingsContext.Consumer>
                {({ fonts }) => (
                    <Query query={ GET_MENU_NUMEROS } variables={{
                        magazine_slug: this.context.magazine.slug,
                    }} fetchPolicy="cache-only">
                        {({loading, error, data, updateQuery, client, networkStatus}) => {
                            let numeros = data.datas.menuNumeros;

                            const classes2 = classNames({
                                'numeros-menu': true,
                                'show-more': (numeros.length > 3)
                            });

                            return(
                                <Query query={ GET_CURRENT_NUMERO }>
                                    {({loading, error, data, client}) => {
                                        let current = data.currentNumero;

                                        return (
                                            <div className={ classes } data-text="true">

                                                {/*<Link to={`/imprimer`}>
                                                    Imprimer
                                                </Link>*/}

                                                <button className="menu-numeros-btn"
                                                        style={ fonts.family3 } onClick={ this.toggleNumeroMenu }>
                                                    <div className="inner">
                                                        <span>{ (clientWidth < 769) ? truncate(current.title, 20) : truncate(current.title, 45)}</span>
                                                        {!this.state.menuShow && <i className="ico-pold-chevron-down" />}
                                                        {this.state.menuShow && <i className="ico-pold-chevron-up" />}
                                                    </div>
                                                </button>

                                                {this.state.menuShow && <div className="numeros-menu-outer" style={ fonts.family4 }>
                                                    {!this.state.isPerfect &&
                                                        <div className="inner-numero-menu">
                                                        <ul className={ classes2 }>
                                                            { numeros.map((numero) => {

                                                                if(numero._id === current._id){
                                                                    return (
                                                                        <li className="current" key={numero._id}>
                                                                            <Link to={`/${numero.slug}`}
                                                                                  onClick={(e) => this.goTo(e, `/${numero.slug}`, numero , current) }>
                                                                                {numero.title} <i className="icon-check" />
                                                                            </Link>
                                                                        </li>
                                                                    )
                                                                }

                                                                return (
                                                                    <li key={numero._id}>
                                                                        <Link to={`/${numero.slug}`} onClick={() => { this.props.toggleMenu(false, numero) } }>
                                                                            {numero.title}
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            }) }
                                                        </ul>
                                                    </div>
                                                    }
                                                </div>}
                                            </div>
                                        )
                                    }}
                                </Query>
                            );
                        }}
                    </Query>
                )}
            </SettingsContext.Consumer>
        )
    }



}

NumeroMenu.contextTypes = {
    magazine: PropTypes.object,
    resetCache: PropTypes.bool
};

const HeaderView = ({ updateWidthScroll, menu, match, toggleMenu, data,
                        scrolled, scrollFX, fetchMore, magazine, currentNumero, logo, fonts, menuShow, isPage, openLink, history, currentArticles,  isSearching, kindView, isNoImg, openLinkAndLoading, client, user }) => {

    let isCurrentNoImg = () => {
        if(!currentArticles || !currentNumero) return false;

        return currentNumero.articles.find((article) => {
            return currentArticles.find((obj) => {
                return (!article.wallpaper.url && article._id === obj._id);
            });
        });
    };

    let noImg;

    if(kindView === "article"){
        noImg = isCurrentNoImg();
        isNoImg(noImg);
    } else {
        noImg = false;
    }

    const classes = classNames({
        'scrolled': scrolled || isPage && !noImg,
        'header': true,
        'isSearching': isSearching,
        'isPage': isPage,
        'menuShow': menu,
        'no-img': noImg
    });

    if(typeof window !== "undefined") {
        if (menu) {
            /*requestAnimationFrame(() => {
                document.body.style.overflowY = "hidden";
            });*/
        } else {
            /*requestAnimationFrame(() => {
                document.body.style.overflowY = "initial";
            });*/
        }
    }

    let logoImg = logo.horizontal;

    if(logo.horizontal_blanc) {
        logoImg = logo.horizontal_blanc;
    }

    if((scrolled && !menu) || isPage || isSearching) {
        logoImg = logo.horizontal;
    }

    return (
        <header className={ classes }>
            {/*!menu && !scrolled && !isPage && <div className="fx-backgrd" data-text="true" />*/}
            <div className="inner">

                {!logo.horizontal &&
                    <h1 style={ fonts.family1 }>
                        <Link data-text="true"
                              to={`/${currentNumero.slug}`}
                              onClick={ (e) => openLink(`/${currentNumero.slug}` , e) }
                        >
                            {logo.carre &&
                                <img src={logo.carre} alt=""/>
                            }
                            <div dangerouslySetInnerHTML={{ __html: magazine.title }} />
                        </Link>
                    </h1>
                }
                {logo.horizontal &&
                    <h1>
                        <Link to={`/${currentNumero.slug}`} onClick={ (e) => openLink(`/${currentNumero.slug}` , e) }>
                            <img src={ logoImg } alt=""/>
                        </Link>
                    </h1>
                }

                <div className="right-bloc">
                    {data.numeros &&
                    <div>
                        {/*<Link to={`/imprimer`}
                              className="imprimer-btn"
                              onClick={ (e) => openLinkAndLoading(`/imprimer` , e) }>
                            Imprimer le rapport
                        </Link>*/}

                        {/*<ExportPDFBtn match={ match }
                                      client={ client }
                                      magazine={ magazine }
                                      user={ user }
                                      currentNumero={ currentNumero }
                                      className="imprimer-btn"
                        />*/}

                        {menuShow && currentNumero.document && <a href={currentNumero.document} target="_new"> <div class="imprimer-btn">
                              <svg className="impressionIcon" width="17px" height="17px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                      <g id="icons8-print-copy" transform="translate(-11.000000, -11.000000)" fill="#000000" fillRule="nonzero">
                                          <path d="M14.5416667,11 L14.5416667,15.9583333 L13.125,15.9583333 C11.9601239,15.9583333 11,16.9184572 11,18.0833333 L11,25.1666667 L14.5416667,25.1666667 L14.5416667,28 L24.4583333,28 L24.4583333,25.1666667 L28,25.1666667 L28,18.0833333 C28,16.9184572 27.0398761,15.9583333 25.875,15.9583333 L24.4583333,15.9583333 L24.4583333,11 L14.5416667,11 Z M15.9583333,12.4166667 L23.0416667,12.4166667 L23.0416667,15.9583333 L15.9583333,15.9583333 L15.9583333,12.4166667 Z M13.125,17.375 L25.875,17.375 C26.2762043,17.375 26.5833333,17.6821291 26.5833333,18.0833333 L26.5833333,23.75 L24.4583333,23.75 L24.4583333,20.9166667 L14.5416667,20.9166667 L14.5416667,23.75 L12.4166667,23.75 L12.4166667,18.0833333 C12.4166667,17.6821291 12.7237958,17.375 13.125,17.375 Z M13.8333333,18.0833333 C13.4431968,18.0833333 13.125,18.4015301 13.125,18.7916667 C13.125,19.1818032 13.4431968,19.5 13.8333333,19.5 C14.2234699,19.5 14.5416667,19.1818032 14.5416667,18.7916667 C14.5416667,18.4015301 14.2234699,18.0833333 13.8333333,18.0833333 Z M15.9583333,22.3333333 L23.0416667,22.3333333 L23.0416667,26.5833333 L15.9583333,26.5833333 L15.9583333,22.3333333 Z" id="Shape"/>
                                      </g>
                                  </g>
                              </svg>
                            </div>
                          </a>
                        }
                        
                        {/*
                          <ExportToPdfAsync numero={ currentNumero } magazine={ magazine }/>
                        */}

                        <Accessibilite fonts={ fonts } font={ fonts.family1 } />
                        <NumeroMenu match={ match } toggleMenu={ toggleMenu }
                                    numeros={ data.numeros }
                                    fetchMore={ fetchMore }/>
                        <SearchBtn
                            isPage={ isPage } numeros={ data.numeros }
                            toggleMenu={ toggleMenu }
                            slug={ currentNumero.slug } history={ history }
                        />
                    </div>}
                </div>

            </div>
            <div className="scroll-block">
                <div/>
                <div className="scroll-inner">
                    <ScrollContext.Consumer>
                        {({ changeScrollWidth, scrollWidth }) => (
                            <ScrollFX updateWidthScroll={ changeScrollWidth } scrollWidth={ scrollWidth } scrollFX={ scrollFX }/>
                        )}
                    </ScrollContext.Consumer>
                </div>
            </div>
        </header>
    )
};

class ScrollFX extends Component {

    constructor(props){
        super(props);

        this.ref = React.createRef();

        this.state = {
            width: "",
        };

        this.handleScroll = this.handleScroll.bind(this);

    }

    handle = () => { let that = this;
        if(typeof window !== "undefined"){
            requestAnimationFrame(that.handleScroll);
        }
    };

    componentDidMount() { let that = this;
        // document.getElementById("root")
        document.addEventListener('scroll', that.handle, false);
    }

    componentWillUnmount() { let that = this;
        document.removeEventListener("scroll", that.handleScroll, false);
    }

    handleScroll() {

        if(typeof window !== "undefined") {

            const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

            let height = document.body.scrollHeight - document.documentElement.clientHeight;
            let widthCalcul = (scrollTop / height) * 100;
            this.props.updateWidthScroll(widthCalcul, scrollTop);
        }

    }

    render() {

        const style = {
            width: this.props.scrollWidth + "%"
        };

        return(
            <div className="scroll-fx" style={ style }  ref={ this.ref } />
        )
    }

}

export default HeaderView;
